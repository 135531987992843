<template>
    <div>
        <input @change="getpolicy($event)" type="file" :accept="accept" :ref="refName" class="upInput"
            :multiple="multiple" :style="`width:${width}px`" />
    </div>
</template>

<script>
    import axios from 'axios'
    import {
        qiniuyun
    } from "../api/api"
    import request from "../api/request"
    export default {
        data() {
            return {
                qiniuUploadURL: '//up-z2.qiniup.com',
            }
        },
        props: ["accept", "refName", "width", "multiple","externalLoading"],
        methods: {
            getpolicy() {
                this.$emit("trueLoading",true)
                let files = this.$refs[this.refName].files
                let name = files[0].name
                request(qiniuyun,{fileName:name,type:this.accept==".mp4"?2:1}).then((res) => {
                    if (res.code === "200") {
                        let length = files.length
                        for (let i = 0; i < length; i++) {
                            this.qiniuyunUpload(files[i], res.data)

                        }
                    }
                });
            },
            qiniuyunUpload(file, qiniuyunToken) {
                let formData = new FormData();
                //注意formData里append添加的键的大小写
                formData.append('key', qiniuyunToken.key)
                formData.append('token', qiniuyunToken.token)
                //如果是base64文件，那么直接把base64字符串转成blob对象进行上传就可以了
                formData.append("file", file);
                axios.post(this.qiniuUploadURL, formData, {
                    'Content-Type': 'multipart/form-data'
                }).then(res => {
                    if (res.data.code == "200") {
                        this.$util.message("suc", "上传成功");
                        this.$emit("getUrl", res.data.data.filename)
                    } else {
                        this.$util.message("err", res.message);
                    }
                })

            },
        }
    }
</script>

<style lang="less" scoped>

</style>