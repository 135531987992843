<template>
  <div class="addArticle">
    <div class="title">
      <span>咨询管理 / </span> <span>咨询师管理 / </span>
      <span class="black">咨询师信息{{ edit ? "新增" : "编辑" }} </span>
    </div>
    <el-card>
      <el-form :model="addForm" label-width="130px" :rules="rules" ref="addForm">
        <el-row :gutter="24">
          <el-col :xs="24" :md="12">
            <el-form-item label="咨询师账户：" prop="counselorUser">
              <el-input v-model="addForm.counselorUser" placeholder="请输入咨询师真实手机号获取验证码登录" @blur="findUserNumber()" maxlength="18">
              </el-input>
            </el-form-item>
            <el-form-item label="咨询师名称：" prop="counselorName">
              <el-input v-model="addForm.counselorName" placeholder="请输入咨询师名称"></el-input>
            </el-form-item>
            <el-form-item label="性别：" prop="counselorSex">
              <el-radio-group v-model="addForm.counselorSex">
                <el-radio v-for="(item, key) in sexList" :key="key" :label="item.value">{{ item.label }}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="出生日期：" prop="counselorSr">
              <el-date-picker v-model="addForm.counselorSr" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                placeholder="请选择出生日期" class="w_100">
              </el-date-picker>
            </el-form-item>
            <el-form-item label=" 证件号：" prop="counselorCard">
              <el-input v-model="addForm.counselorCard" placeholder="请输入证件号"></el-input>
            </el-form-item>
            <el-form-item label="咨询师职称：" prop="counselorZc">
              <el-input v-model="addForm.counselorZc" placeholder="请输入咨询师职称"></el-input>
            </el-form-item>
            <el-form-item label="联系手机：" prop="counselorPhone">
              <el-input v-model="addForm.counselorPhone" placeholder="请输联系手机" maxlength="11"></el-input>
            </el-form-item>
            <el-form-item label="工作单位：" prop="counselorAddress">
              <el-input v-model="addForm.counselorAddress" placeholder="请输入工作单位"></el-input>
            </el-form-item>
            <el-form-item label="推荐星级：" prop="counselorTjxj">
              <el-rate v-model="addForm.counselorTjxj" class="rate_top"></el-rate>
            </el-form-item>
            <el-form-item label="服务标签：" prop="counselorTitleEntities1" >
              <el-select v-model="addForm.counselorTitleEntities1" multiple placeholder="请选择服务标签" class="w_100"
                :filterable="true" @change="changeOption">
                <el-option v-for="(item, index) in consultTitleArr" :key="index" :label="item.titleName"
                  :value="item.titleId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="个人简介：" prop="counselorGrjj">
              <el-input type="textarea" placeholder="请输入个人简介" v-model="addForm.counselorGrjj" :rows="4" show-word-limit>
              </el-input>
            </el-form-item>
            <el-form-item label="擅长简介：" prop="counselorScly">
              <el-input type="textarea" placeholder="请输入擅长简介" v-model="addForm.counselorScly" :rows="4" show-word-limit>
              </el-input>
            </el-form-item>
            <el-form-item label="所属分类：" prop="counselorType">
              <el-select class="w_100" v-model="addForm.counselorType" placeholder="请选择所属分类">
                <el-option v-for="(item, index) in counselorTypeArr" :key="index" :label="item.typeName"
                  :value="item.typeId">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :md="12">
            <el-form-item label="头像：" prop="counselorImg">
              <div class="flex" v-if="uploadStyle==0">
                <el-upload :action="action1" accept=".png,.jpg,.jpeg,.bmp,.webp" :headers="headers"
                  :show-file-list="false" :on-success="ImgSuccess">
                  <el-button size="small" type="primary">选择资源文件</el-button>
                </el-upload>
                <span class="upload_span_text">建议尺寸大小为110pt*110pt，仅支持png/jpg/jpeg/bmp/webp格式</span>
              </div>
              <div v-else>
                <qiniuyun v-if="uploadStyle==2" :accept="'.png,.jpg,.jpeg,.bmp,.webp'" :refName="'fileUp1'" :width="104"
                  @getUrl="getUrl1"></qiniuyun>
                <ossUpload v-else :accept="'.png,.jpg,.jpeg,.bmp,.webp'" :refName="'fileUp1'" :width="104"
                  @getUrl="getUrl1"></ossUpload>
                <el-button size="small" type="primary">选择资源文件</el-button>
                <span class="upload_span_text">建议尺寸大小为110pt*110pt，仅支持png/jpg/jpeg/bmp/webp格式</span>
              </div>
              <div class="img_div">
                <img v-if="addForm.counselorImg" :src="addForm.counselorImg" class="img_110" />
              </div>
            </el-form-item>
            <el-form-item label="证件/资质等：" prop="credentialsEntities">
              <el-upload multiple :action="action1" :show-file-list="false" accept=".png,.jpg,.jpeg,.bmp,.webp"
                :headers="headers" :on-success="handleAvatarSuccess" v-if="uploadStyle==0">
                <el-button size="small" type="primary">选择资源文件</el-button>
              </el-upload>
              <div v-else>
                <qiniuyun v-if="uploadStyle==2" :accept="'.png,.jpg,.jpeg,.bmp,.webp'" :refName="'fileUp2'" :width="104"
                  :multiple="true" @getUrl="getUrl2">
                </qiniuyun>
                <ossUpload v-else :accept="'.png,.jpg,.jpeg,.bmp,.webp'" :refName="'fileUp2'" :width="104"
                  :multiple="true" @getUrl="getUrl2">
                </ossUpload>
                <el-button size="small" type="primary">选择资源文件</el-button>
              </div>
              <div class="img_div" v-if="addForm.credentialsEntities.length">
                <div class="img_240_div fl_l" v-for="(val, i) in addForm.credentialsEntities" :key="i">
                  <img :src="val.credentialsImg" class="img_240" /><i class="el-icon-error close_icon"
                    @click="deleteImg(i)"></i>
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="开通咨询服务：" prop="counselorServeEntitiesList">
          <div class="open_div" :class="openIndex == i ? 'open_blue_border' : ''" v-for="(item, i) in openList" :key="i"
            @click="changeOpenIndex(i)">
            <el-checkbox-group v-model="addForm.counselorServeEntitiesList[i].type"
              @change="changeModel(addForm.counselorServeEntitiesList[i])">
              <el-checkbox :label="item.label" name="type"><img class="middle" :src="item.src" />
                {{ item.text }}</el-checkbox>
            </el-checkbox-group>
            <el-radio-group v-model="addForm.counselorServeEntitiesList[i].radio"
              :disabled="!addForm.counselorServeEntitiesList[i].type==true" @change="changeRadio(i)">
              <el-radio label="2">免费</el-radio>
              <el-radio label="1">￥
                <el-input v-model="addForm.counselorServeEntitiesList[i].serveMoney" class="w_80"
                  :disabled="addForm.counselorServeEntitiesList[i].radio != 1" @input="changeMoney(i)"></el-input>元/小时
              </el-radio>
            </el-radio-group>
            <div class="flex flex1 explain">
              <span>说明：</span>
              <el-input type="textarea" placeholder="请输入备注"
                :disabled="!addForm.counselorServeEntitiesList[i].type==true"
                v-model="addForm.counselorServeEntitiesList[i].serveSm" :rows="1" @input="changeOption"></el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item class="text_center">
          <el-button type="primary" size="small" @click="add('1')">上线</el-button>
          <el-button type="primary" size="small" plain @click="add('2')">保存待投放</el-button>
          <el-button size="small" @click="cancel()">取消</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
  import {
    updateCounselor,
    addCounselor,
    consultTitleFindAllList,
    counselorTypeList,
    files1,
    counselorInfo,
    counselorFindUser
  } from "../../api/api";
  import ossUpload from "../../components/ossUpload"
  import qiniuyun from "../../components/qiniuyun"
  export default {
    components: {
      ossUpload,
      qiniuyun
    },
    data() {
      const checkCounselorTitle =(rule, value, callback) => {
        if (!this.addForm.counselorTitleEntities1.length) {
          return callback(new Error('请选择服务标签'))
        }
        return callback()
      }
      return {
        userNumber: "",
        passwordRules:'',
        addForm: {
          counselorAddress: "", //工作单位
          counselorCard: "", //证件号
          counselorClass: "", //毕业学校
          counselorCreatetime: "", //创建时间
          counselorFastman: "", //最后操作人
          counselorFasttime: "", //最后操作时间
          counselorFlag: "", // 2待上架 1上架 0下架
          counselorGrjj: "", //个人简介
          counselorId: "", //咨询师id
          counselorImg: "", //个人头像
          counselorMan: "", //创建人
          counselorName: "", //姓名
          counselorOrder: "", //编辑排序
          counselorPassword: "", //密码
          counselorPhone: "", //电话
          counselorScly: "", //擅长领域
          counselorSex: "", //性别
          counselorSftj: "", //是否推荐
          counselorSr: "", //出生日期
          counselorTjxj: 0, //推荐星级
          counselorType: "", //所属分类
          counselorUser: "", //账户
          counselorZc: "", //职称
          counselorZyzz: "", //专业资质
          credentialsEntities: [], //资质图片
          counselorTitleEntities1: [], //自定义服务标签
          counselorTitleEntities: [], //服务标签
          counselorServeEntities: [], //开通咨询服务
          counselorServeEntitiesList: [
            //自定义的开通咨询服务
            {
              serveMoney: "", //金额
              serveSm: "", //说明
              serveType: "2", //语音
              radio: "", //单选
              type: "", //是否开通
              serveFlag: "", //是否免费  2免费1收费
            },
            {
              serveMoney: "", //金额
              serveSm: "", //说明
              serveType: "3", //视频
              radio: "", //单选
              type: "", //是否开通
              serveFlag: "", //是否免费  2免费1收费
            },
            {
              serveMoney: "", //金额
              serveSm: "", //说明
              serveType: "4", //咨询
              radio: "", //单选
              type: "", //是否开通
              serveFlag: "", //是否免费  2免费1收费
            },
          ],
        },
        sexList: [{
            value: "1",
            label: "男",
          },
          {
            value: "0",
            label: "女",
          },
        ],
        openList: [{
            label: "1",
            text: "语音咨询",
            src: require("../../assets/images/audio.png"),
          },
          {
            label: "2",
            text: "视频咨询",
            src: require("../../assets/images/video.png"),
          },
          {
            label: "3",
            text: "线下咨询",
            src: require("../../assets/images/online.png"),
          },
        ],
        rules: {
          counselorUser: [{
            required: true,
            message: "请输入咨询师账户"
          }],
          counselorName: [{
            required: true,
            message: "请输入咨询师姓名"
          }],
          counselorSex: [{
            required: true,
            message: "请选择性别"
          }],
          counselorSr: [{
            required: true,
            message: "请选择出生日期"
          }],
          counselorCard: [{
            required: true,
            message: "请输入证件号"
          }],
          counselorZc: [{
            required: true,
            message: "请输入咨询师职称"
          }],
          counselorPhone: [{
            required: true,
            message: "请输入联系手机"
          }],
          counselorTjxj: [{
            required: true,
            message: "请选择推荐星级"
          }],
          counselorTitleEntities1: [{
            required: true,
            validator: checkCounselorTitle,
            trigger: 'change',
            message: "请选择服务标签"
          }, ],
          counselorPassword: [{
            required: true,
            message: "请输入账户密码"
          }, {
            min: 6,
            max: 12,
            message: '长度在 6 到 12 个字符'
          }],
          counselorImg: [{
            required: true,
            message: "请上传头像"
          }],
          counselorGrjj: [{
            required: true,
            message: "请填写个人简介"
          }],
          counselorScly: [{
            required: true,
            message: "请填写擅长简介"
          }],
          counselorServeEntitiesList: [{
            required: true,
            message: "请填写开通咨询服务"
          }, ],
        },
        openIndex: null, //选中下标
        consultTitleArr: [], //服务标签
        counselorTypeArr: [], //所属分类
        action1: files1.url, //上传文件
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: this.$util.getToken("access_token"),
          uid:localStorage.getItem('uid')?localStorage.getItem('uid'):''
        },
      };
    },
    computed: {
      edit() {
        return this.$route.params.id == "true";
      },
      previousForm() {
        return this.$store.state.previousForm;
      },
      uploadStyle() {
        return this.$store.state.uploadStyle;
      },
    },
    watch: {
      "addForm.counselorUser"(val, oldVal) {
        this.addForm.counselorUser = this.$util.isEnNum(val);
      },
      "addForm.counselorTitleEntities1"(val, oldVal) {
        this.addForm.counselorTitleEntities1 = val;
      },
      "addForm.counselorCard"(val, oldVal) {
        this.addForm.counselorCard = this.$util.isEnNum(val);
      },
      "addForm.counselorPhone"(val, oldVal) {
        this.addForm.counselorPhone = this.$util.isNumber0(val);
      },
    },

    created() {
      this.userNumber = ""
      this.getConsultTitleList();
      this.getCounselorTypeList();
      if (!this.edit) {
        this.getConsultant(this.$route.params.id);
      }
      if (this.$route.params.form) {
        this.$store.commit("setParam", {
          previousForm: this.$route.params.form
        });

      }
      this.$store.dispatch("judgeOssFn")
    },
    methods: {
      // 获取服务标签
      getConsultTitleList() {
        this.$request(consultTitleFindAllList).then((res) => {
          if (res.code == "200") {
            this.consultTitleArr = res.data;
          }
        });
      },
      // 获取所属分类
      getCounselorTypeList() {
        this.$request(counselorTypeList).then((res) => {
          if (res.code == "200") {
            this.counselorTypeArr = res.data.list;
          }
        });
      },
      changeMoney(i) {
        this.addForm.counselorServeEntitiesList[i].serveMoney = this.$util.isNumber2(this.addForm
          .counselorServeEntitiesList[i].serveMoney)
        this.$forceUpdate()
      },

      changeOption() {
        this.$forceUpdate();
      },
      changeModel(val) {
        this.$forceUpdate();
        if (val.type == true && val.radio == '') {
          val.radio = '2'
        }
        if (val.type == false) {
          val.radio = '',
            val.serveMoney = '',
            val.serveSm = ''
        }
      },
      // 查询咨询师信息
      getConsultant(id) {
        this.$request(counselorInfo(id)).then((res) => {
          if (res.code == "200") {
            let data = res.data;
            this.addForm = data;
            this.userNumber = this.addForm.counselorUser
            this.addForm.counselorServeEntitiesList = [
              //自定义的开通咨询服务
              {
                serveMoney: "", //金额
                serveSm: "", //说明
                serveType: "2", //语音
                radio: "", //单选
                type: "", //是否开通
                serveFlag: "", //是否免费  2免费1收费
              },
              {
                serveMoney: "", //金额
                serveSm: "", //说明
                serveType: "3", //视频
                radio: "", //单选
                type: "", //是否开通
                serveFlag: "", //是否免费  2免费1收费
              },
              {
                serveMoney: "", //金额
                serveSm: "", //说明
                serveType: "4", //咨询
                radio: "", //单选
                type: "", //是否开通
                serveFlag: "", //是否免费  2免费1收费
              },
            ];
            // 服务标签
            this.addForm.counselorTitleEntities1 = [];
            this.addForm.counselorTitleEntities = [];
            if (data.consultTitleEntities) {
              this.addForm.consultTitleEntities.find((item) => {
                this.consultTitleArr.find((value) => {
                  if (item.titleId == value.titleId) {
                    this.addForm.counselorTitleEntities1.push(value.titleId);
                  }
                });
              });
            }
            // 开通服务
            this.addForm.counselorServeEntities.find((val) => {
              // 自定义
              this.addForm.counselorServeEntitiesList.find((item) => {
                if (val.serveType == item.serveType) {
                  item.type = true;
                  item.serveMoney = val.serveFlag == 1 ? val.serveMoney : "";
                  item.radio = val.serveFlag;
                  item.serveSm = val.serveSm;
                }
              });
            });
            // 资质图片
            this.addForm.credentialsEntities = this.addForm.credentialsEntities ?
              this.addForm.credentialsEntities : [];
            // 星级推荐
            this.addForm.counselorTjxj = Number(this.addForm.counselorTjxj || 0);
            this.$forceUpdate()
          }
        });
      },
      findUserNumber() {
        if (this.userNumber == this.addForm.counselorUser || !this.addForm.counselorUser) return
        this.$request(counselorFindUser(this.addForm.counselorUser)).then((res) => {
          if (res.code === "200") {
            let data = res.data;
            if (data === "false") {
              this.addForm.counselorUser = this.userNumber || "";
              this.$util.message("err", "该账户已存在");
            }
          }
        })
      },
      add(val) {
        this.$refs.addForm.validate((result) => {
          if (result) {
            let list = [];
            let url = this.edit ? addCounselor : updateCounselor;
            this.addForm.counselorFlag = val;
            // 开通咨询服务
            this.addForm.counselorServeEntitiesList.find((item) => {
              if (item.type) {
                list.push(item);
              }
              item.serveFlag = item.radio;
            });
            this.addForm.counselorServeEntities = list;
            // 服务标签
            this.addForm.counselorTitleEntities1.find((item) => {
              this.consultTitleArr.find((value) => {
                if (item == value.titleId) {
                  this.addForm.counselorTitleEntities.push({
                    titleId: item,
                  });
                }
              });
            });
            this.addForm.counselorPassword = this.$util.Encrypt(this.addForm.counselorPassword)
            this.$request(url, this.addForm).then((res) => {
              if (res.code === "200") {
                let data = res.data;
                this.$util.message("suc", "操作成功");
                this.$router.push({
                  name: "consultant",
                  params: {
                    form: this.previousForm
                  }
                });
              }
            });
          }
        });
      },
      cancel(){
        this.$router.push({
            name: "consultant",
              params: {
              form: this.previousForm
            }
        });
      },
      // 上传头像
      ImgSuccess(res, file) {
        if (res.code == "200") {
          this.$util.message("suc", "上传成功");
          this.addForm.counselorImg = res.data && res.data[0];
          this.$refs.addForm.validate((result) => {
          return true
           })
         }else{
          this.$util.message("err", res.message);
         }
      },
      // 上传头像-阿里云
      getUrl1(val) {
        this.addForm.counselorImg = val
        this.$refs.addForm.validate((result) => {
          return true
        })
      },
      // 上传资质
      handleAvatarSuccess(res, file) {
        if (res.code == "200") {
          this.$util.message("suc", "上传成功");
          let data = res.data;
          data.find((i) => {
            this.addForm.credentialsEntities.push({
              credentialsImg: i
            });
          });
        } else {
          this.$util.message("err", res.message);
        }

      },
      // 上传资质-阿里云
      getUrl2(val) {
        this.addForm.credentialsEntities.push({
          credentialsImg: val
        });
      },
      // 删除资质图片
      deleteImg(i) {
        this.addForm.credentialsEntities.splice(i, 1);
      },
      // 点击咨询服务
      changeOpenIndex(i) {
        this.openIndex = i;
      },
      // 改变单选-免费
      changeRadio(i) {
        if (this.addForm.counselorServeEntitiesList[i].radio == 2) {
          this.addForm.counselorServeEntitiesList[i].serveMoney = "";
        }
        this.$forceUpdate();
      },
    },
  };
</script>

<style lang="less" scoped>
  .addArticle {
    .rate_top {
      margin-top: 10px;
    }

    .img_110 {
      width: 110px;
      height: 110px;
      border: 1px solid #eeeeee;
    }

    .img_div {
      margin-top: 11px;
    }

    .img_240_div {
      position: relative;
    }

    .img_240 {
      width: 170px;
      height: 240px;
      border: 1px solid #eeeeee;
      margin-right: 24px;
    }

    .close_icon {
      color: #666666ff;
      font-size: 24px;
      position: absolute;
      left: 158px;
      top: -11px;
    }

    .open_div {

      border: 1px solid #d9d9d9;
      padding: 12px 16px 8px 16px;
      border-radius: 4px;
      margin-bottom: 16px;

      .el-radio-group {
        margin: 0 30px;
      }

      .explain {
        span {
          width: 60px;
        }

        /deep/.el-textarea__inner {
          margin-top: 6px;
        }
      }

      /deep/.el-radio {
        margin-top: 6px;
      }
    }

    .open_blue_border {
      border-color: #007ff6ff;
    }

    @media (max-width: 1100) {
      .open_div {
        display: block;
      }
    }

    @media (min-width: 1100px) {
      .open_div {
        display: flex;
      }
    }
  }
</style>