<template>
    <div>
        <input @change="getpolicy($event)" type="file" :accept="accept" :ref="refName" class="upInput"
            :multiple="multiple" :style="`width:${width}px`" />
    </div>
</template>

<script>
    import axios from 'axios'
    import {
        getpolicy
    } from "../api/api"
    import request from "../api/request"
    export default {
        data() {
            return {
            }
        },
        props: ["accept", "refName", "width", "multiple","externalLoading"],
        methods: {
            getpolicy() {
                this.$emit("trueLoading",true)
                let files = this.$refs[this.refName].files
                let name = files[0].name
                let formData = new FormData();
                 formData.append('fileName', name); //存储在oss的文件路径
                request(getpolicy,formData).then((res) => {
                    if (res.code === "200") {
                        let length = files.length
                        for (let i = 0; i < length; i++) {
                            this.ossUpload(files[i], res.data)
                        }
                    }
                });
            },
            ossUpload(file, ossToken) {
                const filename = file.name;
                let formData = new FormData();
                //注意formData里append添加的键的大小写
                formData.append('key', ossToken.dir + filename); //存储在oss的文件路径
                formData.append('OSSAccessKeyId', ossToken.accessid); //accessKeyId
                formData.append('policy', ossToken.policy); //policy
                formData.append('Signature', ossToken.signature); //签名
                formData.append('callback', ossToken.callback); //回调
                formData.append('success_action_status', 200); //成功后返回的操作码
                //如果是base64文件，那么直接把base64字符串转成blob对象进行上传就可以了
                formData.append("file", file);
                axios.post(ossToken.host, formData, {
                    'Content-Type': 'multipart/form-data'
                }).then(res => {
                    if (res.data.code == "200") {
                        this.$util.message("suc", "上传成功");
                        this.$emit("getUrl", res.data.data.filename)
                    } else {
                        this.$util.message("err", res.message);
                    }
                })

            },
        }
    }
</script>

<style lang="less" scoped>

</style>